<template>
  <div>
    <b-row class="mb-4">
      <b-col cols="12">
        <my-table
          :rows="transactions"
          :columns="$t('transactionsTableColumns')"
          :is-loading="isLoading"
        >
          <template v-slot="{props}">
            <span v-if="props.column.field === 'id'">
              <b-button
                variant="primary"
                :to="({ name: 'deliverysummary', params: { id: props.row.id } })"
              >{{ props.row.reference_id }}</b-button>
            </span>

            <span v-else-if="props.column.field === 'recipient.complete'">
              {{ props.row.recipient.complete }}
            </span>

            <span v-else-if="props.column.field === 'date_added'">
              {{ $helpers.formatDate(props.row.date_added) }}
            </span>

            <span v-else-if="props.column.field === 'delivery_date'">
              {{ $helpers.formatDateTime(props.row.delivery_date) }}
              <feather-icon
                v-if="props.row.delivery_date_changed"
                :id="`delivery-tooltip-${props.row.id}`"
                size="20"
                icon="InfoIcon"
                style="color: red"
              />
              <b-tooltip
                :target="`delivery-tooltip-${props.row.id}`"
                triggers="hover"
                no-fade
              >
                Revised date due to high volume of delivery
              </b-tooltip>
            </span>

            <span v-else-if="props.column.field === 'status.text'">
              <b-badge
                class="p-1 w-100"
                :variant="props.row.status.color_name"
              >
                {{ props.row.status.text }}
                <feather-icon
                  v-if="props.row.status.value === 13 || props.row.status.value === 14"
                  :id="`accepted-tooltip-${props.row.id}`"
                  icon="InfoIcon"
                />
                <b-tooltip
                  v-if="props.row.status.value === 13 || props.row.status.value === 14"
                  :target="`accepted-tooltip-${props.row.id}`"
                  triggers="hover"
                  no-fade
                >
                  {{ props.row.status.value === 13 ? 'For verification within 24hrs from SenangPay' : 'Pay now inside my cart.' }}
                </b-tooltip>
              </b-badge>
            </span>

            <span v-else-if="props.column.field === 'shipment_type'">
              <b-badge
                :variant="props.row.status.color_name"
                class="p-1 text-white font-weight-bold w-100"
                :class="props.row.shipment_type === 'Smarttruck' ? 'text-uppercase bg-primary ' : 'bg-success'"
              >
                {{ props.row.shipment_type }}
              </b-badge>
            </span>

            <span v-else-if="props.column.field === 'sub_total'">
              {{ $helpers.formatTotal(props.row.sub_total) }}
            </span>

            <span v-else-if="props.column.field === 'pickup_charge'">
              {{ $helpers.formatTotal(props.row.pickup_charge) }}
            </span>

            <span v-else-if="props.column.field === 'tax_charge_percentage'">
              {{ props.row.tax_charge_percentage }}
            </span>

            <span v-else-if="props.column.field === 'price'">
              {{ $helpers.formatTotal(props.row.price) }}
            </span>

            <span v-else-if="props.column.field === 'invoice'">
              <b-button
                v-if="props.row.invoice"
                variant="primary"
                class="w-100"
                :href="props.row.invoice ? props.row.invoice.receipt : '#'"
                target="_blank"
              >{{ $t('src.views.pages.inside.deliveryTransaction.download') }}</b-button>

              <b-button
                v-if="!props.row.invoice"
                class="w-100"
                variant="primary"
                :disabled="!props.row.payment || downloading"
                @click.prevent="handleDownloadInvoice(props.row)"
              >
                <b-spinner
                  v-if="downloading && selectedRow === props.row.id"
                  class="mr-50 p-0"
                  small
                />
                <span v-else>{{ $t('src.views.pages.inside.deliveryTransaction.download') }}</span>
              </b-button>
            </span>
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
        </my-table>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import MyTable from '@/components/MyTable.vue'
import axios from '@axios'

export default {
  name: 'DeliveryTransaction',
  components: {
    MyTable,
  },

  data() {
    return {
      searchId: '',
      downloading: false,
      selectedRow: null,
    }
  },
  computed: {
    ...mapGetters({
      transactions: 'orders/orders',
      isLoading: 'orders/isLoading',
    }),
  },
  created() {
    this.$store.dispatch('orders/fetchOrders', { query: 'orders_for=b2bpro&filter_by=delivery_transaction' })
  },
  methods: {
    async handleDownloadInvoice(row) {
      this.downloading = true
      this.selectedRow = row.id
      const response = await axios.get(`/download-invoice/${row.logpoint_id ? row.logpoint_id : row.payment.id}/${row.payment_method}`, { responseType: 'blob' })
      const fileURL = window.URL.createObjectURL(new Blob([response.data]))
      const fileLink = document.createElement('a')

      fileLink.href = fileURL
      fileLink.setAttribute('download', `invoice-${row.payment.reference_number ? row.payment.reference_number : row.reference_id}.pdf`)
      document.body.appendChild(fileLink)
      fileLink.click()
      this.selectedRow = null
      this.downloading = false
    },
  },
}
</script>
<style lang="scss" scoped>
  @import '@core/scss/vue/libs/vue-good-table.scss';
</style>
